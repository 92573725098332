import styled from 'styled-components';

export const TermsBar = styled.div`
  background: #00539f;
  width: 100%;
  height: auto;
  text-align: center;
`;

export const Terms = styled.div`
  padding: 50px;
  width: 880px;
  margin: 0 auto;
  @media (max-width: 870px) {
    width: 100%;
    padding: 30px;
  }
`;

export const NoteStyle = styled.p`
  font-size: 13px;
  color: #fff;
  font-family: 'Helvetica-Condensed', arial, sans-serif;
  margin: 0 0 20px 0;
  padding: 0;
`;
